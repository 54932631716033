<template>
  <div class="members-directory">
    <ApiErrorMessage :message="apiErrorMessage" />
    <v-dialog hide-overlay v-model="imageSlider" max-width="600px">
      <v-carousel class="carousel-wrapper">
        <span class="carousel-close-icon" @click="imageSlider = false"><v-icon>mdi-close</v-icon></span>
        <v-carousel-item v-for="(item, i) in images" :key="i">
          <img :src="item.url" style="width: 600px; height: auto" :alt="item.url" eager />
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <h3 class="box-title green-text">
            {{ $t("bookLog.title") }}
          </h3>
          <div class="car-counts-wrap">
            <label>
              {{ $t("bookLog.cars") }}:
            </label>
            <h4 class="car-count">
              <span>{{ (bookLog.data && bookLog.data.length) || 0 }}</span>
            </h4>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="box-top-right">
          <v-text-field hide-details="auto" v-model="searchText" elevation="0" height="40" dense
            :placeholder="$t('posts.searchForWords')" solo @input="fetchMembers"
            append-icon="mdi-magnify"></v-text-field>
          <button class="btn customBtn pattern-btn
                " @click="$router.push({ name: 'AddVehicle' })">
            <span>{{ $t("bookLog.addVehicle") }}</span>
          </button>
        </div>
      </div>
      <div class="custom-card">
        <div class="custom-box">
          <v-data-table :header-props="{ sortIcon: null }" :disable-sort="true" :headers="headers" :items="bookLog.data"
            :single-expand="singleExpand" :expanded.sync="expanded" fixed-header :show-expand="false"
            class="color-light-green booklog-table-wrapper" :loading="loading" :page.sync="page"
            :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
            <template v-slot:item.purchase_date="{ item }">
              {{ item.purchase_date | MMDDYYdatefilter }}
            </template>
            <template v-slot:item.vin="{ item }">
              <span class="vinbreak">{{
                item.vin.substring(0, 9) + " " + item.vin.substring(9, 17)
              }}</span>
            </template>
            <template v-slot:item.make="{ item }">
              <span class="trim-vin-wrapper">{{ item.make }} / {{ item.model }} / {{ item.year }} /
                {{ item.trim }}</span>
            </template>
            <template v-slot:item.purchase_cost="{ item }">
              {{ item.purchase_cost | USCurrencyfilter }}
            </template>
            <template v-slot:item.action="{ item }">
              <a v-if="item.medias.length > 0" @click="
                imageSlider = true;
              images = item.medias;
              "><v-icon>mdi-camera</v-icon></a>
              <a @click="gotoDetails(item.id)"><v-icon>mdi-pencil</v-icon></a>&nbsp;
              <a @click="
                emailDialog = true;
              id = item.id;
              "><v-icon>mdi-email</v-icon></a>&nbsp;
              <a class="pdf-download" target="blank" :href="item.pdf_url"><v-icon>mdi-printer</v-icon></a>
            </template>
          </v-data-table>
          <div class="text-center custom-pagination">
            <!-- <v-pagination v-model="page" :length="pageCount" @input="fetchMembers"></v-pagination> -->
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p>Showing {{ startEntry }} to {{ endEntry }} of {{ totalEntries }} entries</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  Previous
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <v-dialog :attach="true" v-model="emailDialog" max-width="400px"
          :content-class="'hide-overflow-y image-slider'">
          <v-card>
            <v-card-title> {{ $t("bookLog.emailDialogTitle") }}</v-card-title>
            <v-card-text>
              <v-text-field v-model="email" @input="$v.email.$touch()" @blur="$v.email.$touch()"
                :error-messages="emailErrors" :label="$t('bookLog.email')" outlined></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn height="40" large class="red darken-4 red-gradient white--text text-capitalize"
                @click="emailDialog = !emailDialog">
                {{ $t("bookLog.close") }}
              </v-btn>
              <v-btn height="40" small color="green darkan-4 green-gradient white--text" :loading="saving"
                :disabled="($v.email.$invalid && $v.email.$anyDirty) || saving" @click="sendPdfViaEmail()">
                {{ $t("bookLog.sendEmail") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "BookLog",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalEntries: "",


      loading: false,
      page: 1,
      searchText: null,
      saving: false,
      id: null,
      emailDialog: false,
      pageCount: 0,
      itemsPerPage: 0,
      selectedMember: {},
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      imageSlider: false,
      images: [],
      email: null,
    };
  },
  validations: {
    email: { required, email },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },


    ...mapGetters({
      bookLog: "bookLog/getBookLog",
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required &&
        errors.push(this.$t("bookLog.validations.emailIsRequired"));
      !this.$v.email.email &&
        errors.push(this.$t("bookLog.validations.emailIsInvalid"));
      return errors;
    },
    headers() {
      return [
        {
          text: this.$t("bookLog.purchaseDate"),
          align: "left",
          value: "purchase_date",
          width: "13%",
        },
        {
          text: this.$t("bookLog.makeModelTrim"),
          align: "left",
          value: "make",
          width: "34%",
        },
        {
          text: this.$t("bookLog.vin"),
          align: "left",
          value: "vin",
          width: "15%",
        },
        {
          text: this.$t("bookLog.cost"),
          align: "left",
          value: "purchase_cost",
          width: "12%",
        },
        {
          text: this.$t("bookLog.color"),
          align: "left",
          value: "color",
          width: "10%",
        },
        {
          text: this.$t("bookLog.action"),
          align: "left",
          value: "action",
          width: "16%",
        },
      ];
    },
  },
  async mounted() {
    await this.fetchMembers();
  },

  methods: {
    ...mapActions({
      getBookLog: "bookLog/getBookLog",
      sendPdf: "bookLog/sendPdf",
    }),

    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchMembers(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchMembers(); // Fetch new data based on the current page
      }
    },

    async fetchMembers() {
      this.loading = true;
      try {
        if (this.searchText) {
          this.page = 1;
        }
        await this.getBookLog({
          page: this.currentPage,
          // items_per_page: ITEMS_PER_PAGE,
          search: this.searchText,
        });

        if (this.bookLog.meta) {
          this.totalEntries = this.bookLog.meta.total;
          this.itemsPerPage = parseInt(this.bookLog.meta.per_page);
          this.pageCount = this.bookLog.meta.last_page;
        } else {
          this.itemsPerPage = this.bookLog.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    gotoDetails(id) {
      this.$router.push({
        name: "UpdateVehicle",
        params: { vehicleId: id },
      });
    },

    async sendPdfViaEmail() {
      this.$v.email.$touch();
      if (!this.$v.email.$invalid) {
        try {
          this.saving = true;
          await this.sendPdf({ email: this.email, id: this.id });
          this.emailDialog = false;
          this.email = null;
        } catch ({ message }) {
          this.apiErrorMessage = message;
        } finally {
          this.saving = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
